var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('b10-subheader',{attrs:{"label":"Datos del contrato","icon":_vm.$vuetify.icons.values.contratos}}),_c('ValidationProvider',{attrs:{"name":"objeto","rules":_vm.formRules.objeto},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Objeto","error-messages":errors},model:{value:(_vm.form.objeto),callback:function ($$v) {_vm.$set(_vm.form, "objeto", $$v)},expression:"form.objeto"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"tcontrato","rules":_vm.formRules.tcontrato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.tcontratos,"item-text":"descripcion","label":"Tipo de contrato","clearable":"","error-messages":errors,"return-object":"","no-filter":""},on:{"change":_vm.tcontratoSelected},model:{value:(_vm.form.tcontrato),callback:function ($$v) {_vm.$set(_vm.form, "tcontrato", $$v)},expression:"form.tcontrato"}})]}}])}),_c('b10-subheader',{attrs:{"label":"Inicio extrardinario","icon":_vm.$vuetify.icons.values.calendar}}),_c('ValidationProvider',{attrs:{"name":"inicio_extraordinario"}},[_c('v-checkbox',{attrs:{"label":"Inicio con carácter urgente"},on:{"change":_vm.inicioExtraordinarioSelected},model:{value:(_vm.form.inicio_extraordinario),callback:function ($$v) {_vm.$set(_vm.form, "inicio_extraordinario", $$v)},expression:"form.inicio_extraordinario"}})],1),_c('ValidationProvider',{attrs:{"name":"dur_fexcepcional","rules":_vm.formRules.dur_fexcepcional},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-date-time-picker',{ref:"durFexcepcional",attrs:{"title":"Fecha y hora","disabled":!_vm.form.inicio_extraordinario,"error-messages":errors},model:{value:(_vm.form.dur_fexcepcional),callback:function ($$v) {_vm.$set(_vm.form, "dur_fexcepcional", $$v)},expression:"form.dur_fexcepcional"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"dur_causa","rules":_vm.formRules.dur_causa},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Causa","disabled":!_vm.form.inicio_extraordinario,"error-messages":errors},model:{value:(_vm.form.dur_causa),callback:function ($$v) {_vm.$set(_vm.form, "dur_causa", $$v)},expression:"form.dur_causa"}})]}}])}),_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Entidad comunicada","disabled":!_vm.form.inicio_extraordinario},model:{value:(_vm.form.dur_entidad_comunicada),callback:function ($$v) {_vm.$set(_vm.form, "dur_entidad_comunicada", $$v)},expression:"form.dur_entidad_comunicada"}}),_c('b10-subheader',{attrs:{"label":"Más datos","icon":_vm.$vuetify.icons.values.info}}),_c('ValidationProvider',{attrs:{"name":"fcontrato","rules":_vm.formRules.fcontrato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-date-picker',{attrs:{"title":"Fecha de contrato","error-messages":errors},model:{value:(_vm.form.fcontrato),callback:function ($$v) {_vm.$set(_vm.form, "fcontrato", $$v)},expression:"form.fcontrato"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"idserie_contrato","rules":_vm.formRules.idserie_contrato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.seriesContrato,"item-value":"idserie_contrato","item-text":"descripcorta","label":"Serie de contrato","clearable":"","error-messages":errors},model:{value:(_vm.form.idserie_contrato),callback:function ($$v) {_vm.$set(_vm.form, "idserie_contrato", $$v)},expression:"form.idserie_contrato"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }