import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectTcontrato', method: 'tcontrato.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectSerieContrato', method: 'serieContrato.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idcontrato', id)
    const resp = await Vue.$api.call('contrato.select', {
      filter: apiFilter
    })
    return resp.data.result.dataset[0]
  },
}
